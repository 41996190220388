import {Resource} from '@rest-hooks/rest';
const address = process.env.REACT_APP_REST_API_ADDRESS;
const apiUrl = address ? address : "/api";

class ContactEmailResource extends Resource {
    id = undefined;
    name = null
    mail = null
    phone = null
    body = null

    pk() {
        return this.id?.toString();
    }

    static urlRoot = `${apiUrl}/email/`;
}

class ContactResource extends Resource {
    id = undefined;
    name = '';
    mail = null;
    phone = null;
    birth_date = null;
    occupation = null;
    address = null;
    website = null;

    pk() {
        return this.id?.toString();
    }

    static urlRoot = `${apiUrl}/contacts/`;
}


class TextResource extends Resource {
    id = undefined;
    name = '';
    data = null;

    pk() {
        return this.name;
    }

    static urlRoot = `${apiUrl}/texts`;
}


class FieldResource extends Resource {
    id = undefined;
    name = '';
    description = null;
    short = null;

    pk() {
        return this.id?.toString();
    }

    static urlRoot = `${apiUrl}/fields`;
}


class JobResource extends Resource {
    id = undefined;
    company = {
        name: null,
        website: null,
        logo: null
    };
    role = null;
    start_date = null;
    end_date = null;
    description = null;
    technologies = [];

    pk() {
        return this.id?.toString();
    }

    static urlRoot = `${apiUrl}/jobs`;
}


class SchoolResource extends Resource {
    id = undefined;
    address = null;
    fields = null;
    start_year = null;
    end_year = null;
    website = null;
    logo = null;

    pk() {
        return this.id?.toString();
    }

    static urlRoot = `${apiUrl}/schools`;
}

class ProjectResource extends Resource {
    id = undefined;
    name = null;
    link = null;
    description = null;

    pk() {
        return this.id?.toString();
    }

    static urlRoot = `${apiUrl}/projects`;
}

class CollabResource extends Resource {
    id = undefined;
    project = null;
    contact = null;

    pk() {
        return this.id?.toString();
    }

    static urlRoot = `${apiUrl}/collabs`;
}


export {
    ContactEmailResource,
    ContactResource,
    TextResource,
    FieldResource,
    JobResource,
    SchoolResource,
    ProjectResource,
    CollabResource
};
