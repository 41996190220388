import {useResource} from "rest-hooks";
import {CollabResource, ContactResource, ProjectResource} from "../../restapi/endpoints";
import TitledPaperBox from "../../components/containers/TitledPaperBox";
import ScienceIcon from '@mui/icons-material/Science';
import {Grid} from "@mui/material";
import ProjectItem from "./ProjectItem";

export default function Projects() {
    const projects = useResource(ProjectResource.list(), {});
    const collabs = useResource(CollabResource.list(), {});
    const contacts = useResource(ContactResource.list(), {});

    const get_collabs = project => {
        return collabs
            .filter(collab => collab.project === project.id)
            .map(collab => collab.contact)
            .map(contact_id => contacts.find(contact => contact.id === contact_id))
    };

    const projects_with_collabs = projects.map(project => {
        return {
            ...project,
            collabs: get_collabs(project)
        }
    });

    return (
        <Grid container spacing={4}>
            <TitledPaperBox
                title="Projects"
                IconComponent={ScienceIcon}
            >
                {projects_with_collabs.map(project => (
                    <ProjectItem key={project.id} project={project}/>
                ))}
            </TitledPaperBox>
        </Grid>
    );
}
