import {useResource} from "rest-hooks";
import {JobResource} from "../../restapi/endpoints";
import TitledPaperBox from "../../components/containers/TitledPaperBox";
import HistoryIcon from '@mui/icons-material/History';
import {Box, Link, Stack, Typography} from "@mui/material";

export default function ResumeJob({order}) {
    const jobs = useResource(JobResource.list(), {});
    const format_date = (date) => (new Date(date)).toDateString();

    return (
        <TitledPaperBox
            title="Work Experience"
            IconComponent={HistoryIcon}
            order={order}
        >
            {jobs.map(job => (
                <Box key={job.id} mb={2}>
                    <Stack direction="row" alignItems="center" spacing={2}>
                        {job.company.logo
                            ? <a href={job.company.website}>
                                <img
                                    style={{
                                        display: "block",
                                        maxWidth: 96,
                                        maxHeight: 96,
                                        width: "auto",
                                        height: "auto",
                                    }}
                                    src={job.company.logo}
                                    alt={job.company.name}
                                />
                            </a>
                            : <img
                                src="http://placehold.jp/99ccff/003366/96x96.png?text=No%20logo"
                                alt="No images"
                            />}
                        <div>
                            <Typography variant="h5" sx={{color: "primary.light"}}>{job.company.name}</Typography>
                            <Typography variant="subtitle2" sx={{color: "secondary.light"}}>
                                {format_date(job.start_date)} - {job.end_date ? format_date(job.end_date) : "Present"}
                            </Typography>
                            <Typography>{job.description}</Typography>
                            <Link color="info.main" href={job.company.website}>{job.company.website}</Link>
                            <Typography variant="subtitle1" sx={{color: "success.light"}}>{job.technologies.map((tech, i) => {
                                return i === job.technologies.length - 1 ? tech : `${tech} | `;
                            })}</Typography>
                        </div>
                    </Stack>
                </Box>
            ))}
        </TitledPaperBox>
    );
}
