import {BrowserRouter} from "react-router-dom";
import {createTheme, ThemeProvider} from "@mui/material";
import {themeOptions} from "./config/theme";
import Main from "./components/Main";

const theme = createTheme(themeOptions);

function App() {
    return (
        <BrowserRouter>
            <ThemeProvider theme={theme}>
                <Main/>
            </ThemeProvider>
        </BrowserRouter>
    );
}

export default App;
