import {useResource} from "rest-hooks";
import {Grid} from "@mui/material";
import {ContactResource} from "../restapi/endpoints";
import ResumeTitle from "./resume/ResumeTitle";
import ResumeProfile from "./resume/ResumeProfile";
import ResumeExperience from "./resume/ResumeExperience";
import ResumeJob from "./resume/ResumeJob";
import ResumeSchool from "./resume/ResumeSchool";

export default function Resume({id}: { id: number }) {
    const me = useResource(ContactResource.detail(), {id});

    return (
        <Grid container spacing={4}>
            <ResumeTitle me={me} order={0}/>
            <ResumeProfile order={1}/>
            <ResumeExperience order={2}/>
            <ResumeJob order={3}/>
            <ResumeSchool order={4}/>
        </Grid>
    );
}