import {Box, Grid, Paper, Slide} from "@mui/material";
import {useEffect, useState} from "react";

export default function PaperBox({
                                     padding = 2,
                                     xs = 12,
                                     children,
                                     extraPaperProps,
                                     extraBoxProps,
                                     order,
                                     ...props
                                 }) {
    const [on, setOn] = useState(() => false);

    useEffect(() => {
        setOn(true);
    }, [])

    return (
        <Slide direction="left" in={on} style={{transitionDelay: on ? `${order * 100}ms` : "0ms"}}>
            <Grid item xs={xs} {...props}>
                <Paper {...extraPaperProps}>
                    <Box p={padding} {...extraBoxProps}>
                        {children}
                    </Box>
                </Paper>
            </Grid>
        </Slide>
    );
}