import TitledPaperBox from "../containers/TitledPaperBox";
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import SendIcon from '@mui/icons-material/Send';
import {Alert, Box, Button, Grid, Snackbar, TextField, Typography} from "@mui/material";
import {useForm} from "react-hook-form";
import {useRef, useState} from "react";
import {useController} from "rest-hooks";
import {ContactEmailResource} from "../../restapi/endpoints";
import ReCAPTCHA from 'react-google-recaptcha'

const bodyMaxCharCount = 4096;

export default function ContactForm() {
    const {register, setValue, handleSubmit, resetField, formState: {errors}} = useForm();
    const [charCount, setCharCount] = useState(0);
    const {fetch} = useController();
    const captchaRef = useRef();

    const [openSuccessAlert, setOpenSuccessAlert] = useState(false);
    const [openFailAlert, setOpenFailAlert] = useState(false);
    const [captchaKey, setCaptchaKey] = useState(() => {
        setValue("captcha", null, {shouldValidate: true, shouldDirty: true});
        return null;
    });

    const onSubmit = (e) => {
        fetch(ContactEmailResource.create(), {}, e)
            ?.then(() => {
                setOpenFailAlert(false);
                setOpenSuccessAlert(true);
                resetField("subject");
                resetField("body");
                setCharCount(0);
            })
            ?.catch(err => {
                setOpenSuccessAlert(false);
                setOpenFailAlert(true);
                console.log("Reason the message could not be sent:", err);
            });
        captchaRef.current.reset();
    }

    const handleCaptcha = (e) => {
        setCaptchaKey(e);
        setValue("captcha", e, {shouldValidate: true, shouldDirty: true});
    }

    const handleInput = (e) => {
        const length = e.target.value.length;
        setCharCount(Number.isInteger(length) ? length : 0);
    }

    const handleCloseAlert = setFunc => (e, r) => {
        if (r === "clickaway")
            return;
        setFunc(false);
    }

    return (
        <TitledPaperBox
            title="Contact Me"
            IconComponent={AlternateEmailIcon}
        >
            <Box
                component="form"
                onSubmit={handleSubmit(onSubmit)}
            >
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            label="Subject"
                            fullWidth
                            required
                            inputProps={{maxLength: 128}}
                            helperText="Write me about anything: questions, suggestions, bug reports, propositions..."
                            {...register("subject")}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Message body"
                            multiline
                            fullWidth
                            required
                            minRows={4}
                            maxRows={20}
                            inputProps={{maxLength: bodyMaxCharCount}}
                            onInput={handleInput}
                            helperText={`Remaining characters: ${bodyMaxCharCount - charCount}`}
                            {...register("body")}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            label="Name"
                            fullWidth
                            required
                            defaultValue="Anonymous"
                            inputProps={{maxLength: 32}}
                            {...register("name")}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            label="Email (optional)"
                            fullWidth
                            inputProps={{maxLength: 320}}
                            error={errors.mail !== undefined}
                            helperText={errors.mail?.message ?? ""}
                            {...register("mail", {
                                required: false,
                                pattern: {
                                    value: /\S+@\S+\.\S+/,
                                    message: "Incorrect email format"
                                }
                            })}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            label="Phone (optional)"
                            fullWidth
                            inputProps={{maxLength: 32}}
                            {...register("phone")}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <ReCAPTCHA
                            onChange={handleCaptcha}
                            sitekey="6LcPj9wdAAAAAFwfVxEHAPzMNIb9orzyDgUv5lb3"
                            theme="dark"
                            ref={captchaRef}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <Button
                            type="submit"
                            variant="contained"
                            size="large"
                            disabled={!captchaKey}
                            endIcon={<SendIcon/>}
                        >Submit</Button>
                        {!captchaKey && <Typography variant="subtitle2" color="error">
                            Please validate the captcha!
                        </Typography>}
                    </Grid>
                </Grid>
            </Box>
            <Snackbar
                open={openSuccessAlert}
                autoHideDuration={8000}
                onClose={handleCloseAlert(setOpenSuccessAlert)}
            >
                <Alert severity="success">Message sent successfully! Thank you for your time!</Alert>
            </Snackbar>
            <Snackbar
                open={openFailAlert}
                autoHideDuration={8000}
                onClose={handleCloseAlert(setOpenFailAlert)}
            >
                <Alert severity="error">An error occurred: something may not have been accepted by the backend
                    validation, or maybe it is dead 😕 (which should not happen since I coded and deployed things so
                    perfectly ✨)</Alert>
            </Snackbar>
        </TitledPaperBox>
    );
}