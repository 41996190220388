import {useResource} from "rest-hooks";
import {TextResource} from "../../restapi/endpoints";
import FaceIcon from '@mui/icons-material/Face';
import TitledPaperBox from "../../components/containers/TitledPaperBox";
import {Typography} from "@mui/material";

export default function ResumeProfile({order}) {
    const profile = useResource(TextResource.detail(), {name: "My profile"});

    return (
        <TitledPaperBox
            title="Profile"
            IconComponent={FaceIcon}
            order={order}
        >
            <Typography>{profile.data}</Typography>
        </TitledPaperBox>
    );
}