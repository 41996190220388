import PaperBox from "../components/containers/PaperBox";
import {Box, Grid, Stack, Typography} from "@mui/material";
import TitledPaperBox from "../components/containers/TitledPaperBox";
import LanguageIcon from '@mui/icons-material/Language';

const frameworks = [
    {
        name: "React",
        sub: "Javascript Front-end Framework",
        logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/a/a7/React-icon.svg/langfr-220px-React-icon.svg.png",
        logoBackgroundColor: "transparent"
    },
    {
        name: "MUI",
        sub: "Material UI React Component Library",
        logo: "https://mui.com/static/logo.png"
    },
    {
        name: "Rest Hooks",
        sub: "For asynchronously and reliably fetching data from the Rest API endpoints",
        logo: "https://resthooks.io/img/rest_hooks_logo.svg",
        logoBackgroundColor: "transparent"
    },
    {
        name: "Django",
        sub: "High Level Python Web Back-end Framework",
        logo: "https://static.djangoproject.com/img/logo-django.42234b631760.svg",
        logoBackgroundColor: "transparent"
    },
    {
        name: "Django REST Framework",
        sub: "The Django way to make a RestAPI",
        logo: "https://www.django-rest-framework.org/img/logo.png",
        logoBackgroundColor: "white"
    },
    {
        name: "Stack Overflow",
        sub: "How else could I have fixed all those error?",
        logo: "https://upload.wikimedia.org/wikipedia/fr/9/95/Stack_Overflow_website_logo.png",
        logoBackgroundColor: "white"
    }
];

export default function About() {
    return (
        <Grid container spacing={4}>
            <PaperBox>
                <Typography variant="h2" align="center">About</Typography>
            </PaperBox>
            <TitledPaperBox
                title="Carefully crafted using"
                IconComponent={LanguageIcon}
            >
                {frameworks.map(framework => (
                    <Box key={framework.name} mb={2}>
                        <Stack direction="row" alignItems="center" spacing={2}>
                            <img
                                style={{
                                    display: "block",
                                    maxWidth: 96,
                                    maxHeight: 96,
                                    width: "auto",
                                    height: "auto",
                                    backgroundColor: framework.logoBackgroundColor
                                }}
                                src={framework.logo}
                                alt={framework.name}
                            />
                            <div>
                                <Typography variant="h3" sx={{color: "info.main"}}>
                                    {framework.name}
                                </Typography>
                                <Typography variant="subtitle2" sx={{color: "secondary.light"}}>
                                    {framework.sub}
                                </Typography>
                            </div>
                        </Stack>
                    </Box>
                ))}
            </TitledPaperBox>
        </Grid>
    );
}