import {Avatar, Grid, Typography} from "@mui/material";
import PaperBox from "../../components/containers/PaperBox";

export default function ResumeTitle({me, order}) {
    return (
        <PaperBox order={order}>
            <Grid container spacing={2}>
                <Grid item xs={2} md={2}>
                    <Avatar
                        alt="me"
                        src={`${process.env.PUBLIC_URL}/me.jpg`}
                        sx={{
                            width: 128,
                            height: 128
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={10}>
                    <Typography variant="h2">{me.name}</Typography>
                    <Typography variant="h3">{me.occupation}</Typography>
                </Grid>
            </Grid>
        </PaperBox>
    );
}
