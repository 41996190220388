import PaperBox from "../components/containers/PaperBox";
import {Grid, Link, Stack, Typography} from "@mui/material";
import TitledPaperBox from "../components/containers/TitledPaperBox";
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GitHubIcon from '@mui/icons-material/GitHub';
import {createElement} from "react";
import ContactForm from "../components/forms/ContactForm";

export default function Contact() {
    const entries = [
        {
            type: "linkedin",
            content: "LinkedIn",
            icon: LinkedInIcon,
            typographyProps: {
                component: Link,
                href: "https://www.linkedin.com/in/baptisteparsy/",
                sx: {color: "info.main"}
            }
        },
        {
            type: "github",
            content: "GitHub",
            icon: GitHubIcon,
            typographyProps: {
                component: Link,
                href: "https://github.com/titarch",
                sx: {color: "info.main"}
            }
        }
    ];

    return (
        <Grid container spacing={4}>
            <PaperBox>
                <Typography variant="h2" align="center">Contact</Typography>
            </PaperBox>
            <ContactForm/>
            <TitledPaperBox
                title="Coordinates"
                IconComponent={ConnectWithoutContactIcon}
            >
                <Stack direction="column" spacing={1}>
                    {entries.map(entry =>
                        <Stack key={entry.type} direction="row" alignItems="center" spacing={1}>
                            {createElement(entry.icon, {color: "secondary", sx: {fontSize: 32}})}
                            <Typography variant="h6" {...entry.typographyProps}>{entry.content}</Typography>
                        </Stack>
                    )}
                </Stack>
            </TitledPaperBox>
        </Grid>
    );
}