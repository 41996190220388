import {useParams} from "react-router-dom";
import {useResource} from "rest-hooks";
import {CollabResource, ContactResource, ProjectResource} from "../restapi/endpoints";
import TitledPaperBox from "../components/containers/TitledPaperBox";
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';
import {Grid, Link, Stack, Typography} from "@mui/material";
import ScienceIcon from '@mui/icons-material/Science';
import ProjectItem from "./projects/ProjectItem";

export default function ContactInfo() {
    const {id} = useParams();
    const contact = useResource(ContactResource.detail(), {id});
    const collabs = useResource(CollabResource.list(), {});
    const projects = useResource(ProjectResource.list(), {});
    const related_projects = collabs
        .filter(collab => collab.contact === contact.id)
        .map(collab => collab.project)
        .map(project_id => projects.find(project => project.id === project_id));

    return (
        <Grid container spacing={4}>
            <TitledPaperBox
                title="Contact Info"
                IconComponent={ConnectWithoutContactIcon}
            >
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <Typography variant="h3" color="primary.light">{contact.name}</Typography>
                    {contact.mail && <Link
                        variant="h6"
                        color="info.light"
                        href={`mailto:${contact.mail}`}
                    >
                        {contact.mail}
                    </Link>}
                </Stack>
                <Typography variant="h4" color="secondary.light">{contact.occupation}</Typography>
            </TitledPaperBox>
            <TitledPaperBox
                title={`Group Projects with ${contact.name}`}
                IconComponent={ScienceIcon}
            >
                {related_projects.map(project => (
                    <ProjectItem key={project.id} project={project} hideCollabs/>
                ))}
            </TitledPaperBox>
        </Grid>
    );
}
