import TitledPaperBox from "../../components/containers/TitledPaperBox";
import WorkIcon from '@mui/icons-material/Work';
import {useResource} from "rest-hooks";
import {FieldResource} from "../../restapi/endpoints";
import {Box, Typography} from "@mui/material";

export default function ResumeExperience({order}) {
    const fields = useResource(FieldResource.list(), {});

    return (
        <TitledPaperBox
            title="Technical Experience"
            IconComponent={WorkIcon}
            order={order}
        >
            {fields.map(field => (
                <Box key={field.id} mb={2}>
                    <Typography variant="h5" sx={{color: "primary.light"}}>{field.name}</Typography>
                    <Typography>{field.description}</Typography>
                </Box>
            ))}
        </TitledPaperBox>
    );
}