import Resume from "../pages/Resume";
import Contact from "../pages/Contact";
import About from "../pages/About";
import Projects from "../pages/projects/Projects";
import ContactInfo from "../pages/ContactInfo";

const resume_contact_id = 1;

const routes = [
    {
        path: '/resume',
        component: <Resume id={resume_contact_id}/>
    },
    {
        path: '/projects',
        component: <Projects/>
    },
    {
        path: '/contact',
        component: <Contact/>
    },
    {
        path: '/about',
        component: <About/>
    },
    {
        path: '/contacts/:id',
        component: <ContactInfo/>
    }
];

export default routes;
