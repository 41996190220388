import {ThemeOptions} from "@mui/material"

export const themeOptions: ThemeOptions = {
    palette: {
        mode: 'dark',
        primary: {
            main: '#9a65c7',
        },
        secondary: {
            main: '#ff79c6',
        },
        background: {
            default: '#282a36',
            paper: '#44475a',
        },
        text: {
            primary: '#f8f8f2',
        },
        success: {
            main: '#50fa7b',
        },
        info: {
            main: '#8be9fd',
        },
        error: {
            main: '#ff5555',
        },
        warning: {
            main: '#ffb86c',
        },
    },
};