import PaperBox from "../../components/containers/PaperBox";
import {Box, Slide, Stack, Typography} from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';
import {useEffect, useState} from "react";

export default function TitledPaperBox({
                                           title = "Title",
                                           IconComponent = InfoIcon,
                                           iconFontSize = 42,
                                           order,
                                           children
                                       }) {
    const [on, setOn] = useState(false);

    useEffect(() => {
        setOn(true)
    }, []);

    return (
        <>
            <Slide in={on} direction="left" style={{transitionDelay: on ? `${order * 100}ms` : "0ms"}}>
                <Box p={2} m={2} pb={0} mb={-2} display="flex">
                    <Stack direction="row" alignItems="center" spacing={1}>
                        <IconComponent color="secondary" sx={{fontSize: iconFontSize}}/>
                        <Typography variant="h3">{title}</Typography>
                    </Stack>
                </Box>
            </Slide>
            <PaperBox order={order + 0.5}>
                {children}
            </PaperBox>
        </>
    )
}
