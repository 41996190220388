import {useResource} from "rest-hooks";
import {SchoolResource} from "../../restapi/endpoints";
import TitledPaperBox from "../../components/containers/TitledPaperBox";
import SchoolIcon from '@mui/icons-material/School';
import {Box, Link, Stack, Typography} from "@mui/material";

export default function ResumeSchool({order}) {
    const schools = useResource(SchoolResource.list(), {});

    return (
        <TitledPaperBox
            title="Education"
            IconComponent={SchoolIcon}
            order={order}
        >
            {schools.map(school => (
                <Box key={school.id} mb={2}>
                    <Stack direction="row" alignItems="center" spacing={2}>
                        {school.logo
                            ? <a href={school.website}>
                                <img
                                    style={{
                                        display: "block",
                                        maxWidth: 96,
                                        maxHeight: 96,
                                        width: "auto",
                                        height: "auto",
                                    }}
                                    src={school.logo}
                                    alt={school.name}
                                />
                            </a>
                            : <img
                                src="https://placehold.jp/99ccff/003366/96x96.png?text=No%20logo"
                                alt="No images"
                            />}
                        <div>
                            <Typography variant="h5" sx={{color: "primary.light"}}>{school.name}</Typography>
                            <Typography variant="subtitle2" sx={{color: "secondary.light"}}>
                                {school.start_year} - {school.end_year ?? "Present"}
                            </Typography>
                            <Typography>{school.fields}</Typography>
                            <Link color="info.main" href={school.website}>{school.website}</Link>
                        </div>
                    </Stack>
                </Box>
            ))}
        </TitledPaperBox>
    );
}
