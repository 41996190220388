import TitledPaperBox from "../components/containers/TitledPaperBox";
import ErrorIcon from '@mui/icons-material/Error';
import {Grid} from "@mui/material";

function ErrorPage({error}) {
    return (
        <Grid container spacing={4}>
            <TitledPaperBox
                title="Error"
                IconComponent={ErrorIcon}
            >
                {error.status} {error.response && error.response.statusText}
            </TitledPaperBox>
        </Grid>
    );
}

export {
    ErrorPage
}