import {Box, Link, Typography} from "@mui/material";

export default function ProjectItem({project, hideCollabs = false}) {
    return (
        <Box mb={2}>
            <Typography variant="h5" color="primary.light">{project.name}</Typography>
            <Typography>{project.description}</Typography>
            <Link color="info.main" href={project.link}>{project.link}</Link>
            {!hideCollabs && project.collabs.length > 0 && <Typography>
                Collaborators: {project.collabs.map((collab, i) => (
                <div key={collab.id} style={{display: "inline"}}>
                    <Link
                        color="secondary.light"
                        href={`/contacts/${collab.id}`}
                    >
                        {collab.name}
                    </Link>
                    <Typography
                        display="inline"
                        variant="caption"
                        color="secondary.light"
                    >{i < project.collabs.length - 1 ? " | " : ""}</Typography>
                </div>
            ))}
            </Typography>}
        </Box>
    )
}
