import {Box, CircularProgress, Container, CssBaseline} from "@mui/material";
import MenuContent from "./menu/MenuContent";
import {Navigate, Route, Routes} from "react-router-dom";
import routes from "../config/routes";
import {Suspense} from "react";
import {NetworkErrorBoundary} from "rest-hooks";
import {ErrorPage} from "../pages/Error";

export default function Main() {
    return (
        <Box sx={{display: 'flex'}}>
            <CssBaseline/>
            <Box
                component="main"
                sx={{
                    backgroundColor: 'background.default',
                    flexGrow: 1,
                    height: '100vh',
                    overflow: 'auto',
                }}
            >
                <MenuContent/>
                <Container maxWidth="lg" sx={{mt: 4, mb: 4, bgcolor: 'inherit'}}>
                    <Suspense fallback={<CircularProgress/>}>
                        <NetworkErrorBoundary fallbackComponent={ErrorPage}>
                            <Routes>
                                {routes.map(route => (
                                    <Route
                                        key={route.path}
                                        path={route.path}
                                        element={route.component}
                                    />
                                ))}
                                <Route index element={
                                    <Navigate to="/resume"/>
                                }/>
                            </Routes>
                        </NetworkErrorBoundary>
                    </Suspense>
                </Container>
            </Box>
        </Box>
    );
}