const menuItems = [
    {
        value: '/resume',
        primaryText: 'Resume'
    },
    {
        value: '/projects',
        primaryText: 'Projects'
    },
    {
        value: '/contact',
        primaryText: 'Contact'
    },
    {
        value: '/about',
        primaryText: 'About'
    }
];

export default menuItems;
