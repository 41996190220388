import {AppBar, Box, Tab, Tabs, Toolbar} from "@mui/material";
import {Link, useLocation} from "react-router-dom";
import menuItems from "../../config/menuItems";

export default function MenuContent() {
    const location = useLocation();

    return (
        <>
            <AppBar position="static" enableColorOnDark>
                <Toolbar>
                    <Box width="100%">
                        <Tabs
                            value={location.pathname}
                            indicatorColor="secondary"
                            textColor="inherit"
                            centered
                        >
                            {menuItems.map(entry => (
                                <Tab
                                    key={entry.value}
                                    value={entry.value}
                                    label={entry.primaryText}
                                    component={Link}
                                    to={entry.value}
                                />
                            ))}
                        </Tabs>
                    </Box>
                </Toolbar>
            </AppBar>
        </>
    );
}
